<template>
  <CRow>
    <!--<CCol sm="6" lg="3">
      <CWidgetDropdown color="primary" header="9.823" text="Members online">
        <template #default>
          <CDropdown
            color="transparent p-0"
            placement="bottom-end"
          >
            <template #toggler-content>
              <CIcon name="cil-settings"/>
            </template>
            <CDropdownItem>Action</CDropdownItem>
            <CDropdownItem>Another action</CDropdownItem>
            <CDropdownItem>Something else here...</CDropdownItem>
            <CDropdownItem disabled>Disabled action</CDropdownItem>
          </CDropdown>
        </template>
        <template #footer>
          <CChartLineSimple
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="[65, 59, 84, 84, 51, 55, 40]"
            point-hover-background-color="primary"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>-->
    <!--:caret="false"-->
    <!--<CCol sm="6" lg="3">
      <CCard>
        <CCardHeader>
          Standard usage
        </CCardHeader>
        <CCardBody>
          This content is in card body component.
        </CCardBody>
        <CCardFooter>
          Standard Footer.
        </CCardFooter>
      </CCard>
    </CCol>
    <CCol sm="6" lg="3">
      <CCard color="primary" class="text-center" body-wrapper text-color="white">
        <blockquote class="card-blockquote">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
          <footer>Someone famous in
            <cite title="Source Title">Source Title</cite>
          </footer>
        </blockquote>
      </CCard>
    </CCol>-->
    <CCol sm="6" lg="4">
      <CWidgetDropdown color="primary" header="quiz 261421" text="Computer hardware design [2/63]" >
        <template #default>
          <div>
            <CBadge color="danger">กำลังสอบ</CBadge>
          </div>
          <!--<CDropdown color="transparent p-0" placement="bottom-end" :caret="false" size="md">
            <template #toggler-content>
              <CIcon name="cil-settings"/>
              <CBadge color="success">กำลังทำข้อสอบ</CBadge>
            </template>
            
            <CDropdownItem to="/test">test</CDropdownItem>
            <CDropdownItem disabled>Disabled action</CDropdownItem>
          </CDropdown>-->
        </template>
          
        
        <template #footer>
          <!--<CChartLineSimple
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="[1, 18, 9, 17, 34, 22, 11]"
            point-hover-background-color="info"
            :options="{ elements: { line: { tension: 0.00001 }}}"
            label="Members"
            labels="months"
          />-->
          <CRow class="text-center p-4">
            <CCol md="4" >
            </CCol>
            <CCol md="4">
            </CCol>
            <CCol md="4">
              <!--<CButton color="success" style="padding:5px 50px">เข้าสู่Event</CButton>-->
              <b-button variant="warning" to="/courses">เข้าชม</b-button>
            </CCol>
          </CRow>
        </template>
      </CWidgetDropdown>
    </CCol>
    <!--<CCol sm="6" lg="4">
      <CWidgetDropdown color="warning" header="Example" text="Detail">
        <template #default>
          <CDropdown
            color="transparent p-0"
            placement="bottom-end"
          >
            <template #toggler-content>
              <CIcon name="cil-settings"/>
            </template>
            <CDropdownItem>Action</CDropdownItem>
            <CDropdownItem disabled>Disabled action</CDropdownItem>
          </CDropdown>
        </template>
        <template #footer>
          <CRow class="text-center p-4">
            <CCol md="4" >
            </CCol>
            <CCol md="4">
            </CCol>
            <CCol md="4">
              <b-button variant="primary">เข้าชม</b-button>
            </CCol>
          </CRow>
        </template>
      </CWidgetDropdown>
    </CCol>-->
    <!--<CCol sm="6" lg="3">
      <CWidgetDropdown
        color="danger"
        header="9.823"
        text="Members online"
      >
        <template #default>
          <CDropdown
            color="transparent p-0"
            placement="bottom-end"
          >
            <template #toggler-content>
             <CIcon name="cil-settings"/>
            </template>
            <CDropdownItem>Action</CDropdownItem>
            <CDropdownItem>Another action</CDropdownItem>
            <CDropdownItem>Something else here...</CDropdownItem>
            <CDropdownItem disabled>Disabled action</CDropdownItem>
          </CDropdown>
        </template>
        <template #footer>
          <CChartBarSimple
            class="mt-3 mx-3"
            style="height:70px"
            background-color="rgb(250, 152, 152)"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>-->
  </CRow>
</template>

<script>
import { CChartLineSimple, CChartBarSimple } from '../charts/index.js'
import {HTTP} from "@/axios.js";

export default {
  name: 'WidgetsDropdown',
  components: { CChartLineSimple, CChartBarSimple },
  data(){
    return {
    }
  },
  created() {
    this.getCourseByOwner("arnan.s@cmu.ac.th")
  },
  methods: {
    async getCourseByOwner(email){
      console.log("Hi")
      var response
      await HTTP.get(`api/event/info?owner=${email}`)
      .then(res => {
        if (res.data) {
          let resData = JSON.parse(JSON.stringify(res.data))
          console.log("getCourseByOwner(API):",resData);
          response = resData
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    }
  }
}
</script>
