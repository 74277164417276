<template>
  <CRow>
    <CCol md="12" lg="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-newspaper"/>
          <strong>ข่าวสารและประชาสัมพันธ์</strong>
          <!-- <div class="card-header-actions">
            <a 
              href="https://coreui.io/vue/docs/components/carousel" 
              class="card-header-action" 
              rel="noreferrer noopener" 
              target="_blank"
            >
              <small class="text-muted">docs</small>
            </a>
          </div> -->
        </CCardHeader>
        <CCardBody>
          <CCarousel
            arrows
            indicators
            animate
            :interval="8000"
          >
            <CCarouselItem
              image="img/TLIC/website-moving.png"
            />
            <!-- <CCarouselItem
              image="img/TLIC/ocm-banner-opening-exam-1.png"
            />
            <CCarouselItem
              image="img/TLIC/ocm-banner-5.png"
            /> -->
            <!-- <CCarouselItem
              captionHeader="First Slide"
              image="https://picsum.photos/1024/480/?image=52"
              captionText="Nulla vitae elit libero, a pharetra augue mollis interdum."
            /> -->
            <!-- <img
            src="img/TLIC/lms-logo-black.png"
            width=auto 
            height="120"
          /> -->
            <!-- <CCarouselItem
              captionHeader="Blank page"
              :image="{ placeholderColor: 'grey' }"
              captionText="Nulla vitae elit libero, a pharetra augue mollis interdum."
            />
            <CCarouselItem
            image="https://picsum.photos/1024/480/?image=54"
            /> -->
          </CCarousel>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'CarouselsEvent'
}
</script>
