<template>
  <CRow v-if="false">
    <template>
      <CCol lg="6" sm="12" >
        <CCard class="cardhover" v-on:click="$router.push({path: 'kc-moodle'})" style="border-radius: 10px;">
          <!-- <CCardBody class="text-center" style="background-color: #b19cd9;"> -->
          <CCardBody class="text-center">
            <img
              src="img/TLIC/new-kc-logo.png"
              class="my-2 responsive-moodle"
            />
            <div style="color:black" class="pt-3">
              <CButton color="pastelpurple2" shape="pill" class="px-4">
                <!-- <h5><b>เปิดคอร์ส KC-moodle</b></h5> -->
                <b style="font-size:22px">เปิดคอร์ส KC-moodle</b>
              </CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol lg="4" sm="12" v-if="false">
        <CCard class="cardhover" v-on:click="$router.push({path: 'canvas'})" style="border-radius: 10px;">
          <!-- <CCardBody class="text-center" style="background-color: #6288c4;"> -->
          <CCardBody class="text-center">
            <!-- <div style="position: absolute; top: 10px; right: 15px; width: 100px; text-align:right;">
              <CBadge color="primary" class="mfs-auto">New</CBadge>
            </div> -->
            <img
              src="img/TLIC/canvas-logo-crop.png"
              class="my-2 responsive-canvas"
            />
            <div style="color:black" class="pt-3">
              <CButton color="canvasbtn" shape="pill" class="px-4">
                <b style="font-size:22px">เปิดคอร์ส Canvas</b>
                <!-- <h3>เปิดคอร์ส Canvas</h3> -->
              </CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol lg="6" sm="12" v-if="true">
        <CCard class="cardhover" v-on:click="$router.push({path: 'exam'})" style="border-radius: 10px;">
          <!-- <CCardBody class="text-center" style="background-color: #6288c4;"> -->
          <CCardBody class="text-center">
            <div style="position: absolute; top: 10px; right: 15px; width: 100px; text-align:right;">
              <CBadge color="success" class="mfs-auto">NEW</CBadge>
            </div>
            <img
              src="img/TLIC/exam-logo-3.png"
              height="80"
              class="my-2 responsive-exam"
            />
            <div style="color:black" class="pt-3">
              <CButton color="examblue" shape="pill" class="px-4">
                <b style="font-size:22px">เปิดคอร์ส Exam CMU</b>
              </CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol lg="4" sm="12" v-if="false">
        <CCard class="cardhover" v-on:click="$router.push({path: 'mango'})" style="border-radius: 10px;">
          <!-- <CCardBody class="text-center" style="background-color: #6288c4;"> -->
          <CCardBody class="text-center">
            <div style="position: absolute; top: 10px; right: 15px; width: 100px; text-align:right;">
              <CBadge color="primary" class="mfs-auto">New</CBadge>
            </div>
            <img
              src="img/TLIC/mango-logo-crop.png"
              height="80"
              class="my-2"
            />
            <div style="color:black" class="pt-3">
              <CButton color="mangobtn" shape="pill" class="px-4">
                <b style="font-size:22px">เปิดคอร์ส Mango</b>
              </CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </template>
  </CRow>
</template>

<script>
// import { CChartLineSimple } from '../charts/index.js'

export default {
  name: 'WidgetsBrand',
  components: {
    // CChartLineSimple
  },
  props: {
    noCharts: Boolean
  }
}
</script>

<style scoped>
.c-chart-brand {
  position: absolute;
  width: 100%;
  height: 100px;
}
a { 
  text-decoration: none; 
  color: #464854;
}
</style>
